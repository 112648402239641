import React from 'react';
import styled from 'styled-components';

import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
} from '@oneaudi/feature-app-utils';
import { useFeatureAppEnv } from '../context/FeatureAppEnvContext';

interface Consumption {
  consumption?: string | null;
  emissionCO2?: string | null;
  co2Class?: string | null;
  dischargedCO2Class?: string | null;
  dischargedConsumption?: string | null;
}

interface ConsumptionAndEmissionProps {
  consumptionData: Consumption;
  carlineName: string;
}

const ConsumptionAndEmission: React.FC<ConsumptionAndEmissionProps> = ({
  consumptionData,
  carlineName,
}) => {
  const logger = useFeatureAppEnv().featureServices['s2:logger'];

  const { consumption, emissionCO2, co2Class, dischargedCO2Class, dischargedConsumption } =
    consumptionData;

  const logMissingData = () => {
    if (!consumption) logger.warn(`Missing consumption data for ${carlineName}`);
    if (!emissionCO2) logger.warn(`Missing emission CO2 data for ${carlineName}`);
  };

  const fields = [
    { value: consumption, id: 'consumption' },
    { value: emissionCO2, id: 'emissionCO2' },
    { value: co2Class, id: 'co2Class' },
    { value: dischargedConsumption, id: 'dischargedConsumption' },
    { value: dischargedCO2Class, id: 'dischargedCO2Class' },
  ];

  const validFields = fields.filter((field) => field.value != null);

  logMissingData();

  return (
    <StyledTextWithFootnotes variant="copy2" tint="secondary" data-testid="cae">
      {validFields.map((field, index, array) => (
        <React.Fragment key={field.id}>
          {renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(field.value || ''))}
          {index < array.length - 1 && '; '}
        </React.Fragment>
      ))}
    </StyledTextWithFootnotes>
  );
};

export const StyledTextWithFootnotes = styled(Text)`
  > sup {
    position: relative;
    top: -1em;
    vertical-align: baseline;
  }
`;

export default ConsumptionAndEmission;
