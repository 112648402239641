import { VehicleType } from '../../graphql/types';
import type { CarlineGroup, CarlineQuery } from '../../@types/mapped';

export interface Carline extends CarlineQuery {
  group: string;
}

export type DisplayedVehicleType = Exclude<VehicleType, 'ICEV'> | 'ALL';
export const vehicleTypes: DisplayedVehicleType[] = ['ALL', VehicleType.Bev, VehicleType.Phev];

export const getDefaultVehicleType = () =>
  vehicleTypes.find((vehicleType) => vehicleType === 'ALL');

export const flattenCarlineGroups = (carlineGroups: CarlineGroup[]): Array<Carline> =>
  carlineGroups.flatMap((carlineGroup) =>
    carlineGroup.carlines.map((carline) => ({ ...carline, group: carlineGroup.identifier.id })),
  );

export const filterByCarlineGroup = (carlines: Carline[], selectedCarlineGroups: string[]) =>
  carlines.filter(
    (carline) =>
      selectedCarlineGroups.length === 0 || selectedCarlineGroups.includes(carline.group),
  );

export const filterByBodyType = (carlines: Carline[], selectedBodyTypes: string[]) =>
  carlines.filter((carline) => {
    // we need to filter bodyType by the `bodyType` key and also the `categories` due to inconsistent data
    if (selectedBodyTypes.length === 0) {
      return true;
    }
    if (carline.bodyTypes && carline.bodyTypes.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const bodyType of carline.bodyTypes) {
        if (selectedBodyTypes.includes(bodyType!)) {
          return true;
        }
      }
    }
    return false;
  });

export const filterCarlineGroupsByVehicleType = (
  carlineGroups: CarlineGroup[],
  selectedVehicleType: DisplayedVehicleType | undefined,
) => {
  if (!selectedVehicleType) {
    return [];
  }

  if (selectedVehicleType === 'ALL') {
    return carlineGroups;
  }

  return carlineGroups
    .map((carlineGroup) => {
      const filteredCarlines = carlineGroup.carlines.filter(
        (carline) => carline.vehicleType && carline.vehicleType === selectedVehicleType,
      );
      return {
        ...carlineGroup,
        carlines: filteredCarlines,
      };
    })
    .filter((carlineGroup) => carlineGroup.carlines.length > 0);
};
